import { ThemeStyleDictionary } from './base/base.types'

export type ThemeColor =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple'
  | 'white'
  | 'offWhite'
  | 'greyLight'
  | 'greyMedium'
  | 'grey'
  | 'black'
  | 'disabled'

export type ButtonColor =
  | 'default'
  | 'primary'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple'
  | 'white'
  | 'offWhite'
  | 'black'
  | 'facebook'
  | 'outline'

export const colors: ThemeStyleDictionary = {
  quickPrimary: "#E16056",
  quickDark: "#1E1E32",
  quickSandLighter: "#FFF9F3",
  quickSecondary: "#808DFF",
  quickDarkLighter: "#464654",
  quickSand: "#F0E8E0",
  yes: "#00B463",
  warning: "#FF9A51",
  black: "#000000",
  notice: "#FFCF5E",
  no: "#ED4F6B",
  white: "#ffffff",
}
