import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
    ${tw`flex flex-col items-center`};
    background-color: ${(props) => (props.color ? props.color : "#fff")};
`;

const Line = styled.div`
    //max-width: 1280px;
    ${tw`mx-auto`}
    background-color: ${(props) => (props.color ? props.color : "#0f0f0f")};
    height: 2px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HorizontalRule = ({ block }) => {
    const { backgroundColor, color } = block;
    return (
        <Wrapper color={backgroundColor?.hexcode}>
            <Line color={color?.hexcode} />
        </Wrapper>
    );
};

export default HorizontalRule;

export const query = graphql`
    fragment HorizontalRuleFragment on SanityHorizontalRule {
        _key
        _type
        color {
            hexcode
        }
        backgroundColor {
            hexcode
            colorName
        }
    }
`;
