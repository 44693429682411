import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { MdLockOutline } from "react-icons/md";
import getButtonTextColor from "../../../helpers/getButtonTextColor";

const StyledLink = styled(Link)`
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 1rem;
    ${tw`flex justify-center items-center`}
    font-weight:700;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    :hover {
        box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
    }
    :active {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);;
    }
    p {
        margin: 0; 
        font-size: 0.75rem;
        line-height: 1rem;       
    }
`;

const StyledExternalLink = styled.a`
${tw`flex justify-center items-center px-6 py-2 m-0`}
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    padding: 8px 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    text-decoration: none;
    border-radius: 1rem;
    font-weight:700;
    :hover {
        box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
    }
    :active {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);;
    }
    p {
        margin: 0;
        font-size: 0.75rem;
        line-height: 1rem
    }
`;

// types: {
//     title: string;
//     backgroundColor: { hexcode: string };
//     textColor: { hexcode: string };
//     target: string;
//     internalLink?: string;
//     externalLink?: string;
//     icon: "none" | "lock";
// }

const Button = ({ button }) => {
    return (
        <div>
            {button?.targetPage ? (
                <StyledLink
                    to={`/${button.targetPage?.slug.current}`}
                    backgroundColor={button.backgroundColor?.hexcode}
                    textColor={getButtonTextColor(button.backgroundColor?.colorName)}
                    shadow={button.shadow}
                >
                    {button.logo === "lock" && (
                        <MdLockOutline
                            size={16}
                            color={button.textColor?.hexcode}
                            style={{ marginRight: ".58rem" }}
                        />
                    )}
                    <p>{button.title}</p>
                </StyledLink>
            ) : button?.targetPageExternal ? (
                <StyledExternalLink
                    href={button.targetPageExternal}
                    target="_blank"
                    rel="noopener"
                    backgroundColor={button.backgroundColor?.hexcode}
                    textColor={getButtonTextColor(button.backgroundColor?.colorName)}
                    shadow={button.shadow}
                >
                    {button.logo === "lock" && (
                        <MdLockOutline
                            size={16}
                            color={getButtonTextColor(button.backgroundColor?.colorName)}
                            style={{ marginRight: ".58rem" }}
                        />
                    )}
                    <p style={{color: getButtonTextColor(button.backgroundColor?.colorName)}}>{button.title}</p>
                </StyledExternalLink>
            ) : null}
        </div>
    );
};

export default Button;
