import {colors} from "../theme/colors"
const textColors = {
    Quick_Primary: colors["quickSandLighter"],
    Quick_Dark: colors["quickSandLighter"],
    Quick_Sand_Lighter: colors["quickDark"],
    Quick_Secondary: colors["quickSandLighter"],
    Quick_Dark_Lighter: colors["quickSand"],
    Quick_Sand: colors["quickDark"],
    White: colors["quickDark"],
};

export default (input: string) => textColors[input.replace(/-/g, "_")] || colors["quickSandLighter"];
