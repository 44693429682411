import React from 'react'
import styled from "styled-components"

const Container = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "white"};
    color: ${(props) => (props.color ? props.color : "#1E1E32")};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding-left: 16px;
    padding-right: 16px;
    p {
        margin: 0;
        font-size: 0.75rem;
    }
`;

export default function Tag({children, color, backgroundColor, ...props}) {
    return (
        <Container color={color} backgroundColor={backgroundColor}>
            <p>{children}</p>
        </Container>
    );
}
