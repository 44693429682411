import tw from "twin.macro";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    ${tw`text-base`}
    font-weight: normal;
    scroll-behavior: smooth;
    ${tw`font-body text-quickDark`};
  }

  * {
    &::selection {
    }
    img {
      &::selection {
      }
    }
  }

  body {
    ${tw`bg-quickSand text-quickDark m-0`};
    max-width: 90em;
    margin: 0 auto;
    padding-top: 112px;
    font-family: "BrownLL", sans-serif;
    @media only screen and (max-width: 900px) {
      padding-top: 72px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  address {}

  article {}

  aside {}

  footer {}

  header {}

  main {}

  nav {}

  section {}

  img { width: 100%; display: block; }

  h1, h2, h3, h4, h5, li, span {
    margin-block-start: 0;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 700;
    ${tw`text-7xl`}
    @media only screen and (max-width: 768px) {
      ${tw`text-5xl`}
    }
  }

  h2 {
    line-height: 1.2;
    letter-spacing: -0.02em;
    font-weight: 700;
    ${tw`text-5xl`}
    @media only screen and (max-width: 768px) {
      ${tw`text-3xl`}
    }
  }

  h3 {
    line-height: 1.33;
    letter-spacing: -0.01em;
    font-weight: 700;
    ${tw`text-3xl`}
    @media only screen and (max-width: 768px) {
      ${tw`text-2xl`}
    }
  }

  h4 {
    ${tw`text-3xl`}
    line-height: 1.33;
    letter-spacing: -0.01em;
    font-weight: 700;
    @media only screen and (max-width: 768px) {
      ${tw`text-2xl`}
    }
  }

  h5 {
    ${tw`text-2xl`}
    line-height: 1.5;
    letter-spacing: -0.01em;
    font-weight: 500;
    ${tw`mt-0 mb-3`}
    @media only screen and (max-width: 768px) {
      ${tw`text-xl`}
    }
  }

  h6 {
    line-height: 1.5;
    font-weight: 700;
    ${tw`text-base`}
      @media only screen and (max-width: 768px) {
      ${tw`text-sm`}
    }
  }

  p {
    line-height: 1.5;
    font-weight: inherit;
    margin-top: 0;
    margin-bottom: 1.5rem;
    ${tw`text-base`}
    @media only screen and (max-width: 768px) {
      ${tw`text-sm`}
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    line-height: 1.5;
    font-weight: 700;
    transition: all 0.2s;
    ${tw`text-base`}
    @media only screen and (max-width: 768px) {
      ${tw`text-sm`}
    }
  }
  
  strong {
    font-family: inherit;
    font-weight: 700;
  }

  li {
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  
  input, button {
    font-family: inherit;
    border-radius: 16px;
    padding: 16px;
    font-weight: 400;
    ${tw`text-base`}
    @media only screen and (max-width: 768px) {
      ${tw`text-sm`}
    }
  }

  input[type="submit"], button {
    padding: 16px 32px;
    font-weight: 700;
  }

`;
