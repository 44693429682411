import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { useViewport } from "../../../hooks/useViewport";
import { isViewport } from "../../../helpers";
import Logo from "../Header/Logo";
import ButtonNormal from "../../ui/ButtonNormal";
import Button from "../../ui/Button";
import { FaGripLines } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import danishFlag from "../../../assets/flags/DK.png";
import englishFlag from "../../../assets/flags/GB.png";
import norwegianFlag from "../../../assets/flags/NO.png";

// maintain: Here you have to add a new country
const flags = {
  us: (
    <img
      style={{ width: "24px", margin: "2px 0 0 0" }}
      src={englishFlag}
      alt="Logo"
    />
  ),
  da: (
    <img
      style={{ width: "24px", margin: "2px 0 0 0" }}
      src={danishFlag}
      alt="Logo"
    />
  ),
  no: (
    <img
      style={{ width: "24px", margin: "2px 0 0 0" }}
      src={norwegianFlag}
      alt="Logo"
    />
  ),
};

const StyledHeader = styled.header`
  height: 112px;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  justify-content: space-around;
  ${tw`bg-quickSand flex items-center justify-between z-50 md:px-10 px-4`}
  position: fixed; /* Fixed position - sit on top of the page */
  top: 0;
  backdrop-filter: blur(16px);
  @media only screen and (max-width: 768px) {
    height: 64px;
    backdrop-filter: none;
  }
  a:hover {
    opacity: 0.65;
  }
  a:active {
    opacity: 0.85;
  }
`;

const StyledHamburgerHeader = styled.header`
  height: 64px;
  width: calc(100vw - 2rem);
  top: 0;
  justify-content: space-around;
  ${tw`bg-quickSand flex items-center justify-between z-50 md:px-10 px-4`}
  position: fixed; /* Fixed position - sit on top of the page */
  transition: 0.2s; /* Add a transition effect (when scrolling - and font size is decreased) */
`;

const Dots = styled.div`
  cursor: pointer;
  ${tw`relative`}
  :hover {
    opacity: 0.65;
  }
  :active {
    opacity: 0.85;
  }
`;

const DotsContent = styled.p`
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 0.5;
`;

const ContextMenu = styled.div`
  ${tw`absolute`}
  left: calc(50% - 64px);
  position: absolute;
  text-align: center;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

const Triangle = styled.div`
  position: absolute;
  left: calc(50% - 12px);
  top: -12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
`;

const Bubble = styled.div`
  ${tw`bg-white rounded-2xl flex flex-col`}
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
  padding: 0.5rem;
`;

const ContextMenuHori = styled.div`
  display: flex;
  text-align: center;
  position: absolute;
  left: 3rem;
`;

const TriangleHori = styled.div`
  position: relative;
  top: 12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid white;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
`;

const BubbleHori = styled.div`
  ${tw`bg-white rounded-2xl flex`}
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
`;

const StyledMenu = styled.div`
  width: 100vw;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100vw)")};
  transition: transform 0.3s ease-in-out;
  ${tw`right-0 w-full h-full fixed top-0 flex flex-col text-quickDark bg-quickSand`};
  ::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  div {
    ${tw`ml-4`}
    a {
      margin-bottom: 0 !important;
    }
  }
  a:hover {
    opacity: 1;
  }
  a:active {
    opacity: 1;
  }
`;

const Line = styled.div`
  ${tw`mx-4 bg-quickSandLighter`}
  height: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Header({ children, language, groupName, ...props }) {
  const { allSanityNavigation, allSanityPage } = useStaticQuery(graphql`
    query SanityLayoutQuery {
      allSanityPage {
        edges {
          node {
            id
            country
            groupName
            slug {
              current
            }
          }
        }
      }

      allSanityNavigation {
        edges {
          node {
            headerUS {
              ... on SanityNavLink {
                _key
                _type
                linkText
                inContextMenu
                page {
                  title
                  slug {
                    current
                  }
                }
              }
              ... on SanityExternalLink {
                _key
                _type
                linkAddress
                linkText
                inContextMenu
              }
            }
            headerDA {
              ... on SanityNavLink {
                _key
                _type
                linkText
                inContextMenu
                page {
                  title
                  slug {
                    current
                  }
                }
              }
              ... on SanityExternalLink {
                _key
                _type
                linkAddress
                linkText
                inContextMenu
              }
            }
            headerNO {
              ... on SanityNavLink {
                _key
                _type
                linkText
                inContextMenu
                page {
                  title
                  slug {
                    current
                  }
                }
              }
              ... on SanityExternalLink {
                _key
                _type
                linkAddress
                linkText
                inContextMenu
              }
            }
            headerDE {
              ... on SanityNavLink {
                _key
                _type
                linkText
                inContextMenu
                page {
                  title
                  slug {
                    current
                  }
                }
              }
              ... on SanityExternalLink {
                _key
                _type
                linkAddress
                linkText
                inContextMenu
              }
            }

            buttonsUS {
              backgroundColor {
                hexcode
                colorName
              }
              targetPageExternal
              title
              targetPage {
                slug {
                  current
                }
              }
              logo
            }
            buttonsDE {
              backgroundColor {
                hexcode
                colorName
              }
              targetPageExternal
              title
              targetPage {
                slug {
                  current
                }
              }
              logo
            }
            buttonsDA {
              backgroundColor {
                hexcode
                colorName
              }
              targetPageExternal
              title
              targetPage {
                slug {
                  current
                }
              }
              logo
            }
            buttonsNO {
              backgroundColor {
                hexcode
                colorName
              }
              targetPageExternal
              title
              targetPage {
                slug {
                  current
                }
              }
              logo
            }
          }
        }
      }
    }
  `);

  const {
    headerUS,
    headerDE,
    headerDA,
    headerNO,
    buttonsUS,
    buttonsDE,
    buttonsDA,
    buttonsNO,
  } = allSanityNavigation.edges[0].node;

  const [navToggled, setNavToggled] = useState(false);
  const [dotsToggled, setDotsToggled] = useState(false);

  const [flagsToggled, setFlagsToggled] = useState(false);

  const viewport = useViewport();

  const isPhone = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]);

  const countries = {
    us: headerUS,
    de: headerDE,
    da: headerDA,
    no: headerNO,
  };

  const buttons = {
    us: buttonsUS,
    de: buttonsDE,
    da: buttonsDA,
    no: buttonsNO,
  };

  const displayLanguage = {
    us: "en",
    de: "de",
    da: "dk",
    no: "no",
  };

  const disableBodyScroll = () => {
    if (typeof document !== "undefined") {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "hidden";
    }
  };

  const enableBodyScroll = () => {
    if (typeof document !== "undefined") {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "scroll";
    }
  };

  if (typeof window !== "undefined") {
    window.onscroll = function () {
      scrollFunction();
    };
  }

  const handleClick = () => {
    setDotsToggled(!dotsToggled);
    setFlagsToggled(false);
  };

  const handleFlagClick = () => {
    setDotsToggled(false);
    setFlagsToggled(!flagsToggled);
  };

  function scrollFunction() {
    if (document && document.getElementById("header") && !isPhone) {
      const scroll = document.documentElement.scrollTop;

      if (
        scroll <= 40 ||
        (scroll > 40 &&
          document.getElementById("header").style.height !== "112px")
      ) {
        const height = 112 - (scroll <= 40 ? scroll : 40);
        document.getElementById("header").style.height = height + "px";
      }
    }
  }

  function getRedirectForLanguage(
    language: string,
    groupName?: string
  ): string {
    let country = displayLanguage[language];
    
    if (groupName) {
      return (
        "/" +
        allSanityPage.edges.find(
          (item) =>
            item.node.groupName === groupName && item.node.country === language
        )?.node.slug.current
      );
    }

    return '/' + country;
  }

  return (
    <StyledHeader id="header">
      {!isPhone ? (
        <div tw="flex justify-between w-full items-center md:visible invisible">
          <Logo language={displayLanguage[language]} />
          <div style={{ display: "flex", padding: 0 }}>
            {countries[language]?.map((item, index) => {
              if (!item.inContextMenu) {
                const url =
                  item._type === "navLink"
                    ? "/" + item.page?.slug.current
                    : item.linkAddress;
                if (item._type === "navLink") {
                  return (
                    <Link
                      key={index}
                      tw="text-quickDark"
                      style={{ padding: "1rem" }}
                      to={url}
                    >
                      {item.linkText}
                    </Link>
                  );
                } else {
                  return (
                    <a
                      key={index}
                      tw="text-quickDark"
                      style={{ padding: "1rem" }}
                      href={url}
                    >
                      {item.linkText}
                    </a>
                  );
                }
              }
              return null;
            })}
            {countries[language].find((item) => item.inContextMenu) && (
              <div tw="relative">
                <Dots onClick={handleClick}>
                  <DotsContent tw="m-0 p-4 font-bold">...</DotsContent>
                </Dots>
                {dotsToggled && (
                  <ContextMenu>
                    <Triangle />
                    <Bubble>
                      {countries[language]?.map((item, index) => {
                        if (item.inContextMenu) {
                          const url =
                            item._type === "navLink"
                              ? "/" + item.page?.slug.current
                              : item.linkAddress;
                          if (item._type === "navLink") {
                            return (
                              <Link
                                key={index}
                                tw="text-quickDark"
                                style={{ padding: "1rem" }}
                                to={url}
                              >
                                {item.linkText}
                              </Link>
                            );
                          } else {
                            return (
                              <a
                                key={index}
                                tw="text-quickDark"
                                style={{ padding: "1rem" }}
                                href={url}
                              >
                                {item.linkText}
                              </a>
                            );
                          }
                        }
                        return null;
                      })}
                    </Bubble>
                  </ContextMenu>
                )}
              </div>
            )}
            {countries[language].find((item) => item.inContextMenu) && (
              <div tw="relative">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleFlagClick}
                  tw="m-0 p-4"
                >
                  {flags[language]}
                </div>
                {flagsToggled && (
                  <ContextMenu>
                    <Triangle />
                    <Bubble>
                      {language != "us" && (
                        <Link
                          onClick={handleFlagClick}
                          style={{
                            padding: "0.5rem 1rem",
                          }}
                          to={getRedirectForLanguage("us", groupName)}
                        >
                          <div tw="flex text-quickDark">
                            <p tw="pr-2 mb-0">{flags.us}</p> English
                          </div>
                        </Link>
                      )}
                      {language != "da" && (
                        <Link
                          onClick={handleFlagClick}
                          style={{
                            padding: "0.5rem 1rem",
                          }}
                          to={getRedirectForLanguage("da", groupName)}
                        >
                          <div tw="flex text-quickDark">
                            <p tw="pr-2 mb-0">{flags.da}</p> Dansk
                          </div>
                        </Link>
                      )}
                      {language != "no" && (
                        <Link
                          onClick={handleFlagClick}
                          style={{
                            padding: "0.5rem 1rem",
                          }}
                          to={getRedirectForLanguage("no", groupName)}
                        >
                          <div tw="flex text-quickDark">
                            <p tw="pr-2 mb-0">{flags.no}</p> Norsk
                          </div>
                        </Link>
                      )}{" "}
                    </Bubble>
                  </ContextMenu>
                )}
              </div>
            )}
          </div>
          <ButtonWrapper>
            {buttons[language].map((button, index) => {
              return <Button key={index} button={button} />;
            })}
          </ButtonWrapper>
        </div>
      ) : (
        <div tw="w-full flex justify-between items-center md:invisible">
          <Logo language={displayLanguage[language]} />
          <div tw="flex items-center">
            {buttons[language].map((button, index) => {
              if (button.title === "Sign up") {
                return (
                  <div tw="mr-4">
                    <Button key={index} button={button} />
                  </div>
                );
              }
            })}
            <FaGripLines
              size={32}
              tw="cursor-pointer"
              onClick={() => {
                disableBodyScroll();
                setNavToggled(!navToggled);
              }}
            />
          </div>
        </div>
      )}
      {children}
      <StyledMenu open={navToggled}>
        <StyledHamburgerHeader tw="flex justify-between">
          <Logo
            language={displayLanguage[language]}
            onClick={() => {
              enableBodyScroll();
              setNavToggled(!navToggled);
            }}
          />
          <IoMdClose
            onClick={() => {
              enableBodyScroll();
              setNavToggled(!navToggled);
            }}
            tw="cursor-pointer text-quickDark"
            size={32}
          />
        </StyledHamburgerHeader>
        <div
          tw="flex justify-between mx-4 pb-4 pt-6"
          style={{ marginTop: "64px" }}
          >
          {buttons[language].map((button, index) => {
            return <ButtonNormal key={index} button={button} />;
          })}
        </div>
        {countries[language]?.map((item, index) => {
          if (!item.inContextMenu) {
            const url =
            item._type === "navLink"
            ? "/" + item.page?.slug.current
            : item.linkAddress;
            if (item._type === "navLink") {
              return (
                <Link
                key={index}
                tw="text-quickDark"
                style={{ padding: "1rem" }}
                to={url}
                >
                  {item.linkText}
                </Link>
              );
            } else {
              return (
                <a
                  key={index}
                  tw="text-quickDark"
                  style={{ padding: "1rem" }}
                  href={url}
                >
                  {item.linkText}
                </a>
              );
            }
          }
          return null;
        })}
        {countries[language]?.map((item, index) => {
          if (item.inContextMenu) {
            return (
              <Link
                key={index}
                tw="text-quickDark"
                style={{
                  padding: "1rem",
                }}
                to={`${item.linkAddress ? item.linkAddress : "/" + item.page?.slug.current}`}
              >
                {item.linkText}
              </Link>
            );
          }
          return null;
        })}
        <Line />
        {countries[language].find((item) => item.inContextMenu) && (
          <div tw="relative flex items-center py-8">
            <div
              style={{ cursor: "pointer" }}
              onClick={handleFlagClick}
              tw="m-0 px-4"
            >
              {flags[language]}
            </div>
            {flagsToggled && (
              <ContextMenuHori>
                <TriangleHori />
                <BubbleHori>
                  {language != "us" && (
                    <Link
                      onClick={handleFlagClick}
                      style={{
                        padding: "1rem",
                        height: "1rem",
                      }}
                      to={getRedirectForLanguage("en", groupName)}
                    >
                      <div tw="flex text-quickDark">
                        <p tw="pr-2 mb-0">{flags.us}</p> English
                      </div>
                    </Link>
                  )}
                  {language != "da" && (
                    <Link
                      onClick={handleFlagClick}
                      style={{
                        padding: "1rem",
                        height: "1rem",
                      }}
                      to={getRedirectForLanguage("da", groupName)}
                    >
                      <div tw="flex text-quickDark">
                        <p tw="pr-2 mb-0">{flags.da}</p> Dansk
                      </div>
                    </Link>
                  )}
                  {language != "no" && (
                    <Link
                      onClick={handleFlagClick}
                      style={{
                        padding: "1rem",
                        height: "1rem",
                      }}
                      to={getRedirectForLanguage("no", groupName)}
                    >
                      <div tw="flex text-quickDark">
                        <p tw="pr-2 mb-0">{flags.no}</p> Norsk
                      </div>
                    </Link>
                  )}
                </BubbleHori>
              </ContextMenuHori>
            )}
          </div>
        )}
      </StyledMenu>
    </StyledHeader>
  );
}

export default Header;
