export const convertDate = (date, language) => {

    if(date === null) {
        return "No date";
    }

    // maintain: Here you have to add a new country
    const months = {
        us: {
            "01": "January",
            "02": "February",
            "03": "March",
            "04": "April",
            "05": "May",
            "06": "June",
            "07": "July",
            "08": "August",
            "09": "September",
            "10": "October",
            "11": "November",
            "12": "December",
        },
        de: {
            "01": "Januar",
            "02": "Februar",
            "03": "März",
            "04": "April",
            "05": "Mai",
            "06": "Juni",
            "07": "Juli",
            "08": "August",
            "09": "September",
            "10": "Oktober",
            "11": "November",
            "12": "Dezember",
        },
        da: {
            "01": "januar",
            "02": "februar",
            "03": "marts",
            "04": "april",
            "05": "maj",
            "06": "juni",
            "07": "juli",
            "08": "august",
            "09": "september",
            "10": "oktober",
            "11": "november",
            "12": "december",
        },
        no: {
            "01": "januar",
            "02": "februar",
            "03": "mars",
            "04": "april",
            "05": "mai",
            "06": "juni",
            "07": "juli",
            "08": "august",
            "09": "september",
            "10": "oktober",
            "11": "november",
            "12": "desember",
        },
    };
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    // return date.slice(5, 7)
    return `${day}. ${months[language][month]}`;
};
