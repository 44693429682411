import React from "react";
import { GlobalStyle } from "../../theme/global/global.css";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import "../../theme/global/fonts.css";

export const Layout: React.FC<{
  pageColor?: string;
  children: React.ReactNode;
  language?: string;
  pageBuilder: any;
  groupName: string;
}> = ({ children, language, groupName, pageBuilder }) => {
  const faq = pageBuilder?.find((item) => item?._type == "faq");

  let faqString = `
    {"@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
  `;

  faq?.questions.forEach(q => {
    faqString += `{
      "@type": "Question",
      "name": "${q.question}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${q._rawAnswer[0].children[0].text}"
      }
    },`
  });
  faqString = faqString.slice(0, -1);

  faqString += `]}`;

  const enableBodyScroll = () => {
    if (typeof document !== "undefined") {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "scroll";
    }
  };
  enableBodyScroll();

  return (
    <div>
      <GlobalStyle />
      <Helmet
        htmlAttributes={{
          lang: language === "us" ? "en" : language,
        }}
      >
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="8cdefa75-c813-4948-b65c-0f04fcd8841f"
          type="text/javascript"
          async
        ></script>

        {/* FAQ script */}
        {faq && <script type="application/ld+json">{faqString}</script>}

        <meta
          name="google-site-verification"
          content="UIo9tB0kgTDYewSbHtb4Xw3JvEzoAF0n8Vdwokxj6t4"
        />

        <script>
          {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-W7JN2B7X');
          `}
        </script>
      </Helmet>
      <Header language={language} groupName={groupName}></Header>
      {children}
      <Footer language={language}></Footer>
    </div>
  );
};
