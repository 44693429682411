import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { MdLockOutline } from "react-icons/md";
import getColor from "../../../helpers/getColor";

const StyledLink = styled(Link)`
  ${tw`flex justify-center items-center px-6 py-3 pl-6 font-bold md:mb-0 mb-4`}
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor} !important;
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  :hover {
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
  }
  :active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  p {
    margin: 0;
    font-size: 1rem;
  }
  span {
    font-weight: inherit;
    font-size: 1rem;
  }
`;

const StyledExternalLink = styled.a`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor} !important;
  padding: 12px 24px;
  font-weight: 700;
  border-radius: 1rem;
  ${tw`flex justify-center items-center`}
  ${tw`px-6 py-3 pl-6 font-bold md:mb-0 mb-4`};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  :hover {
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
  }
  :active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  }
  p {
    margin: 0;
    font-size: 1rem;
  }
  span {
    font-weight: inherit;
    font-size: 1rem;
  }
`;

// types: {
//     title: string;
//     backgroundColor: { hexcode: string };
//     textColor: { hexcode: string };
//     target: string;
//     internalLink?: string;
//     externalLink?: string;
//     icon: "none" | "lock";
// }

interface IButton {
  title: string;
  backgroundColor: { hexcode: string };
  textColor: { hexcode: string };
  target: string;
  internalLink?: string;
  externalLink?: string;
  icon: "none" | "lock";
}

const ButtonNormal = ({ button }) => {
  return (
    <div>
      {button?.targetPage ? (
        <StyledLink
          to={`/${button.targetPage?.slug.current}`}
          backgroundColor={button.backgroundColor?.hexcode}
          textColor={getColor(button.backgroundColor?.colorName)}
          shadow={button.shadow}
        >
          {button.logo === "lock" && (
            <MdLockOutline
              size={24}
              color={button.textColor?.hexcode}
              style={{ marginRight: "1rem" }}
            />
          )}
          <span>{button.title}</span>
        </StyledLink>
      ) : button?.targetPageExternal ? (
        <StyledExternalLink
          href={button.targetPageExternal}
          target="_blank"
          rel="noopener"
          backgroundColor={button.backgroundColor?.hexcode}
          textColor={getColor(button.backgroundColor?.colorName)}
          shadow={button.shadow}
        >
          {button.logo === "lock" && (
            <MdLockOutline
              size={24}
              color={button.textColor?.hexcode}
              style={{ marginRight: "1rem" }}
            />
          )}
          <span>{button.title}</span>
        </StyledExternalLink>
      ) : null}
    </div>
  );
};

export default ButtonNormal;
