import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "gatsby";
import LogoSvg from "../../../images/Quickorder-Black.png"
import LogoSvgBright from "../../../images/Quickorder-Sand.png"

const StyledLogo = styled(Link)`
    user-select: none;
    ${tw`flex items-center`}
    img {
        height: 3.5rem;

    }
`;

function Logo({ language, ...props }) {
    return (
        <StyledLogo to={`/${language || ""}`} {...props}>
            {props.bright ? (
                <img src={LogoSvgBright} alt="Quickorder Logo" />
            ) : (
                <img src={LogoSvg} alt="Quickorder Logo" /> 
            )}
        </StyledLogo>
    );
}

export default Logo;

