import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Logo from "../../../components/Layout/Header/Logo";
import PortableText from "@sanity/block-content-to-react";

import {
    TiSocialLinkedinCircular,
    TiSocialFacebookCircular,
    TiSocialTwitterCircular,
} from "react-icons/ti";

const Container = styled.div`
    ${tw`flex flex-col md:flex-row items-center md:mx-10 mx-4 md:p-28 py-16 px-6 bg-quickSand`};
    > div {
        ${tw` flex flex-col mx-auto md:flex-row`}
        max-width: 1280px;
    }
`;

const StyledLink = styled(Link)`
    color: #1e1e32;
    line-height: 130%;
    font-weight: 700;
    ${tw`block mb-4`}
`;

const StyledLinkFooter = styled(Link)`
    ${tw`no-underline text-quickDark opacity-50`}
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    ${tw`flex flex-col`}
    p {
        ${tw`mt-0 mb-16`}
        max-width: 48rem;
    }
    a {
        ${tw`no-underline text-quickDark`}
    }
    a:visited {
        ${tw`no-underline text-quickDark`}
    }
`;

const Line = styled.div`
    ${tw`mx-auto mb-14 bg-quickSandLighter`}/* max-width: 1280px; */
    // background-color: #f0e8e0;
    height: 2px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LinkTitle = styled.h5`
    ${tw`mb-10`}
`;

const StyledLogo = styled(Logo)`
    width: 218px;
`

function Footer({ children, language, ...props }) {
    const { allSanityFooter } = useStaticQuery(graphql`
        query SanityFooterQuery {
            allSanityFooter {
                edges {
                    node {
                        backgroundColorUS {
                            hexcode
                        }
                        helpUS {
                            title
                            slug {
                                current
                            }
                        }
                        informationUS {
                            title
                            slug {
                                current
                            }
                        }
                        productsUS {
                            title
                            slug {
                                current
                            }
                        }
                        _rawTextUs(resolveReferences: { maxDepth: 10 })
                        textUS {
                            _key
                            _type
                        }
                        textColorUS {
                            hexcode
                        }
                        socialButtonsUS {
                            link
                            platform
                        }



                        backgroundColorDA {
                            hexcode
                        }
                        helpDA {
                            title
                            slug {
                                current
                            }
                        }
                        informationDA {
                            title
                            slug {
                                current
                            }
                        }
                        productsDA {
                            title
                            slug {
                                current
                            }
                        }
                        _rawTextDa(resolveReferences: { maxDepth: 10 })
                        textDA {
                            _key
                            _type
                        }
                        textColorDA {
                            hexcode
                        }
                        socialButtonsDA {
                            link
                            platform
                        }


                        backgroundColorNO {
                            hexcode
                        }
                        helpNO {
                            title
                            slug {
                                current
                            }
                        }
                        informationNO {
                            title
                            slug {
                                current
                            }
                        }
                        productsNO {
                            title
                            slug {
                                current
                            }
                        }
                        _rawTextNo(resolveReferences: { maxDepth: 10 })
                        textNO {
                            _key
                            _type
                        }
                        textColorNO {
                            hexcode
                        }
                        socialButtonsNO {
                            link
                            platform
                        }
                    }
                }
            }
        }
    `);

    const getPlatformLogo = (platform, size = 32) => {
        switch (platform) {
            case "facebook":
                return <TiSocialFacebookCircular size={size} />;
            case "linkedin":
                return <TiSocialLinkedinCircular size={size} />;
            case "twitter":
                return <TiSocialTwitterCircular size={size} />;
            default:
                return <></>;
        }
    };
    const {
        backgroundColorUS,
        helpUS,
        informationUS,
        productsUS,
        _rawTextUs,
        textColorUS,
        socialButtonsUS,

        backgroundColorDA,
        helpDA,
        informationDA,
        productsDA,
        _rawTextDa,
        textColorDA,
        socialButtonsDA,

        backgroundColorNO,
        helpNO,
        informationNO,
        productsNO,
        _rawTextNo,
        textColorNO,
        socialButtonsNO,
    } = allSanityFooter.edges[1].node;

    const languages = {
        products: {
            us: productsUS,
            da: productsDA,
            no: productsNO
        },
        information: {
            us: informationUS,
            da: informationDA,
            no: informationNO
        },
        help: {
            us: helpUS,
            da: helpDA,
            no: helpNO
        },
        _rawText: {
            us: _rawTextUs,
            da: _rawTextDa,
            no: _rawTextNo
        },
        socialButtons: {
            us: socialButtonsUS,
            da: socialButtonsDA,
            no: socialButtonsNO
        }
    }

    const translations = {
        product: {
            us: "Product",
            da: "Produkt",
            no: "Produkt"
        },
        help: {
            us: "Help",
            da: "Hjælp",
            no: "Hjelp"
        },
        information: {
            us: "Information",
            da: "Information",
            no: "Informasjon"
        }
    }
    
    const serializers = {
        types: {
            undefined: ({ node }) => {
                return null;
            },
        },
    };
    const currentYear = new Date().getFullYear();

    return (
        <>
            <Container>
                <div>
                    <div tw="flex-1 flex flex-col pr-12">
                        <StyledLogo tw="mb-10" />
                        <StyledPortableText blocks={languages._rawText[language]} />
                    </div>
                    <div tw="flex-1 flex flex-col md:flex-row justify-between">
                        <div>
                            <div tw="mb-10">
                                <LinkTitle>{translations.product[language]}</LinkTitle>
                                {languages.products[language].map(
                                    (item, index) => {
                                        return (
                                            <StyledLink
                                                tw="block"
                                                to={`/${item.slug.current}`}
                                                key={index}
                                            >
                                                {item.title}
                                            </StyledLink>
                                        );
                                    }
                                )}
                            </div>
                            <div tw="mb-10">
                                <LinkTitle>{translations.help[language]}</LinkTitle>
                                {languages.help[language].map(
                                    (item, index) => {
                                        return (
                                            <StyledLink
                                                to={`/${item.slug.current}`}
                                                key={index}
                                            >
                                                {item.title}
                                            </StyledLink>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div tw="mb-10">
                            <LinkTitle>{translations.information[language]}</LinkTitle>
                            {languages.information[language].map(
                                (item, index) => {
                                    return (
                                        <StyledLink
                                            to={`/${item.slug.current}`}
                                            key={index}
                                        >
                                            {item.title}
                                        </StyledLink>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </Container>
            <div tw="flex flex-col items-center md:mx-10 mx-4 md:(px-28) pb-16 px-6 bg-quickSand">
                <Line />
                <div tw="flex justify-between md:flex-row flex-col w-full text-quickDark opacity-50">
                    <p>Quickorder © 2014 – {currentYear} CVR: 33964544</p>
                </div>
            </div>
        </>
    );
}

export default Footer;
